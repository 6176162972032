import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/html-introduction",
  "date": "2014-10-20",
  "title": "HTML INTRODUCTION",
  "author": "admin",
  "tags": ["development", "html"],
  "featuredImage": "feature.jpg",
  "excerpt": "The language of a webpage is HTML. HTML allows you to take a plain text document created in any simple text editor and organize it into lists, link to other webpages, including images, and more."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`HTML stands for HyperText Markup Language. `}</p>
    <h3>{`Hypertext`}</h3>
    <p>{`Overcoming the constraints of written text. Interactive.`}</p>
    <h3>{`Markup Language`}</h3>
    <p>{`A way to literally `}<strong parentName="p">{`Mark Up`}</strong>{` a document to specify attributes, like
different font sizes, lists, links to other webpages, and images.`}</p>
    <h3>{`HTML is written in text files`}</h3>
    <p>{`Just like most programming languages, we type a bunch of HTML into anfile (aka. document) so we can send it around.`}</p>
    <h3>{`To display an HTML file we need a browser`}</h3>
    <p>{`Web Browsers are basically `}<strong parentName="p">{`HTML Readers`}</strong>{`. They understand how to read HTML and display it for you.`}</p>
    <h2>{`History`}</h2>
    <p>{`In 1980, Tim Berners-Lee worked for CERN, where he proposed a better way for researchers there to share and read documents.
So in 1984, he built something called ENQUIRE for CERN, which was made up of Cards (Documents) and Hyperlinks (which connected the Documents). But Tim realized he could do better.`}</p>
    <h2>{`The World Wide Web`}</h2>
    <p>{`With Robert Cailliau, Tim Berners-Lee wrote a proposal in 1990 for
the creation of the World Wide Web.`}</p>
    <p>{`When it started, the WWW was a way for scientists around the world
to create and share their own webpages.`}</p>
    <h2>{`First things first: Content`}</h2>
    <p>{`Before you start making a web page, you’ve got to figure out what
content it is going to show.`}</p>
    <p>{`We’ll start with a single recipe with these sections.`}</p>
    <table>	
	<tr>
		<th>Page Title</th>
		<th>Recipe King</th>
	</tr>
	<tr>
		<td>Page Title</td>
		<td>Pancakes</td>
	</tr>
	<tr>
		<td>Section Name</td>
		<td>Ingredients</td>
	</tr>
    </table>
    <h2>{`Writing HTML`}</h2>
    <p>{`Most of the time, you’ll put your content in between HTML tags, which
have corresponding opening and closing versions.`}</p>
    <h2>{`Use heading tags to define your content hierarchy`}</h2>
    <p>{`Higher heading numbers mean the content that appears
between the headings is less important than lower numbers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>Recipe King</h1>
<h2>Pancakes</h2>
<h3>Ingredients</h3>
`}</code></pre>
    <p>{`Here, `}<strong parentName="p">{`h1`}</strong>{` is more important than `}<strong parentName="p">{`h3`}</strong>{`, or `}<strong parentName="p">{`h2`}</strong>{` for that matter.	`}</p>
    <p>{`Generally speaking:`}</p>
    <p>{`The page title/company name goes in the `}<inlineCode parentName="p">{`<h1>`}</inlineCode>{`
The page main subject goes in the `}<inlineCode parentName="p">{`<h2>`}</inlineCode>{`
`}<inlineCode parentName="p">{`<h3>`}</inlineCode>{` through `}<inlineCode parentName="p">{`<h6>`}</inlineCode>{` are used to organize other divisions of page content`}</p>
    <h2>{`Use paragraph tags for non-heading text`}</h2>
    <p><inlineCode parentName="p">{`<p>`}</inlineCode>{` is called the paragraph tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>Recipe King</h1>
<h2>Pancake</h2>
 
<h3>Ingredients</h3>
...
`}</code></pre>
    <p>{`The highlighted paragraph describes Pancake.`}</p>
    <p>{`Add paragraph content (where necessary) in between heading tags.`}</p>
    <h2>{`Use an unordered list to display a list of things`}</h2>
    <p><inlineCode parentName="p">{`<ul>`}</inlineCode>{` stands for unordered list.
Each list item needs to also be put inside of an `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h3>Ingredients</h3>
<ul>
    <li>2 eggs</li>
    <li>1 pound of sugar</li>
    <li>3 sticks of butter</li>
</ul>
`}</code></pre>
    <p>{`This list of ingredients doesn't need to be in a specific order.`}</p>
    <h2>{`Nesting tags`}</h2>
    <p>{`HTML tags don’t have to always just contain text, they can contain other
HTML tags.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h3>Ingredients</h3>
    <ul>
        <li>2 eggs</li>
        <li>1 pound of sugar</li>
        <li>3 sticks of butter</li>
    </ul>
<h3>Directions!</h3>
...
`}</code></pre>
    <p>{`A tag that contains other tags is called the parent.`}</p>
    <p>{`The tags contained in a parent tag are called children.`}</p>
    <h2>{`Not indenting child tags makes HTML hard to read`}</h2>
    <p>{`The code below is valid HTML. But it's too diffcult to read!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h3>Ingredients</h3>
<ul>
    <li>2 eggs</li>
    <li>1 pound of sugar</li>
    <li>3 sticks of butter</li>
</ul>
`}</code></pre>
    <p>{`Always make sure you indent the code for the readability.`}</p>
    <h2>{`Use an ordered list to show list items in a certain order`}</h2>
    <p>{`If the content in your list does refer to steps to be followed, use an
ordered list.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h3>Ingredients</h3>
<ol>
    <li>Mix eggs, sugar, and butter in a large bowl</li>
    <li>Spread into a non-stick dish</li>
    <li>Bake at 350 degrees for 1 hour</li>
    <li>Let sit at room temperature for 20 minutes</li>
    <li>Eat and enjoy!</li>
</ol>
`}</code></pre>
    <h2>{`Wrapping everything in the body`}</h2>
    <p>{`Any content that appears on a web page should be in between a `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` tag`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<body>
    <h1>Recipe World</h1>
    <h2>Magic Cake</h2>!
    <p>Magic Cake is one of...</p>!
    <h3>Ingredients</h3>
    <ul>...</ul>
    <ol>
    <h3>Directions</h3>
    <ol>...</ol>
</body>
`}</code></pre>
    <p><inlineCode parentName="p">{`<body>`}</inlineCode>{` doesn’t display anything, but helps keep the page content organized.`}</p>
    <h2>{`Adding a head tag`}</h2>
    <p>{`Non-visible stuff goes in the `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` tag. You’ll eventually use the head tag to load other useful scripts, like CSS and JavaScript.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<head>
</head>
<body>
    <h1>Recipe World</h1>
</body>
`}</code></pre>
    <p>{`we'll discuss what goes in the `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` later when we need it.`}</p>
    <h2>{`Make everything a child of a single parent tag`}</h2>
    <p>{`All of your HTML goes inside of the `}<inlineCode parentName="p">{`<html>`}</inlineCode>{` tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
    <head></head>
    <body>
        <h1>Recipe King</h1>
        ...
    </body>
</html>
`}</code></pre>
    <p>{`Notice that we're indenting again so it's easy to see the parent/child relationship`}</p>
    <h2>{`Setting the DOCTYPE to html`}</h2>
    <p>{`The DOCTYPE sets the HTML version.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html>
    <head></head>
    <body>
        <h1>Recipe King</h1>
        ...
    </body>
</html>
`}</code></pre>
    <p>{`The browser can make better decisions about how to display your page when it knows which version of HTML you’re using.`}</p>
    <p>{`That's a barebone HTML page for you. We'll discuss further in the up coming tutorials.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      